<template>
  <div class="req-temp-container">
    <vs-card class="mt-6">
      <div slot="header">
        <div class="flex items-center">
          <h3 class="font-medium">Integrations</h3>
        </div>
      </div>

      <p class="mb-4" v-html="headerDescription"></p>

      <vs-table :data="introducers" class="mt-5 stripes linked-system">
        <template slot="thead">
          <vs-th width="35%">System</vs-th>
          <vs-th width="10%"></vs-th>
          <vs-th width="10%">Status</vs-th>
          <vs-th width="45%">Actions</vs-th>
        </template>

        <template slot-scope="{ data }">
          <vs-tr :key="indextr" v-for="(tr, indextr) in data">
            <vs-td :data="tr.name">
              {{ tr.name }}
            </vs-td>
            <vs-td :data="tr.logo">
              <S3ImageGenerator v-if="tr.logo" :custom-class="'table-image'" :document="tr.logo" :is-base64="false" :key="tr.logo" />
            </vs-td>
            <vs-td :data="tr">
              <span :class="changeStatusColor(tr)">{{ getStatus(tr) }}</span>
            </vs-td>
            <vs-td :data="tr">
              <template v-if="getStatus(tr) == 'Not connected'">
                <router-link v-if="tr.isBoxDiceEnabled" :to="{ name: 'box-and-dice-integrations', params: { type: 'create', introducerId: tr._id } }" class="underline cursor-pointer">
                  Enable integration
                </router-link>
                <a v-else @click="handleAction('add', tr)">Enable integration</a>
              </template>
              <template v-else-if="getStatus(tr) == 'Connected'">
                <span class="flex">
                  <a @click="handleAction('remove', tr, tr.linkRequest._id)">Disable integration</a>
                  <router-link v-if="tr.isBoxDiceEnabled" :to="{ name: 'box-and-dice-integrations', params: { type: 'edit', introducerId: tr._id } }" class="underline cursor-pointer ml-8">Settings</router-link>
                  <span v-if="tr.linkRequest && (tr.linkRequest.status == 'Connected') && tr.isBoxDiceEnabled && checkNextUrl(tr)" @click="handleRefresh(tr._id)">
                    <a class="ml-8 mr-4">Refresh data</a>{{ getSyncedTime(tr) }}
                  </span>
                  <span v-else-if="tr.linkRequest && (tr.linkRequest.status == 'Connected') && tr.isBoxDiceEnabled" class="ml-8 flex-1"><b>Data sync currently in progress</b>, message will clear when complete, wait until complete before creating requests in B&D (this may take up to an hour or so depending on how much data your B&D database holds).</span>
                </span>
              </template>
              <template v-else>
                <a @click="handleAction('accept', tr, tr.linkRequest._id)">Accept link</a>
                <a @click="handleAction('decline', tr, tr.linkRequest._id)">Decline link</a>
              </template>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </vs-card>

    <vs-card class="mt-6">
      <div slot="header">
        <div class="flex items-center">
          <h3 class="font-medium">API tokens</h3>
        </div>
      </div>

      <p>Manage API access tokens to allow any external system to access your account via API (e.g. systems who aren't existing {{appName}} partners, or your own systems).</p>

      <div class="flex justify-end">
        <vs-button class="only-border-btn" size="medium" v-round @click="$router.push({ name: 'api-token-create' })">
          <plus-icon size="1.5x" class="button-icon"></plus-icon>New API token
        </vs-button>
      </div>

      <vs-table :data="users" class="mt-5 stripes">
        <template slot="thead">
          <vs-th>Your system reference</vs-th>
          <vs-th>Description</vs-th>
          <vs-th>Last updated</vs-th>
          <vs-th></vs-th>
        </template>

        <template slot-scope="{ data }">
          <vs-tr :key="indextr" v-for="(tr, indextr) in data">
            <vs-td>{{ tr.systemName }}</vs-td>
            <vs-td>{{ tr.description }}</vs-td>
            <vs-td :data="tr.updatedAt">
              {{ moment(tr.updatedAt) }}<br />
              <span class="text-small">{{ momentTime(tr.updatedAt) }}</span>
            </vs-td>
            <vs-td :data="tr">
              <a class="pl-5 pr-5" @click="editToken(tr._id)">Edit</a>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </vs-card>

    <vs-popup
      :title="title"
      class="close-icon-hidden custom-popup-decline filter-popup"
      :active.sync="linkPopupActive"
      style="color: rgb(255, 255, 255)"
      background-color="rgba(255,255,255,.6)"
    >
      <p class="text-gray-font" v-html="description"></p>
      <div class="popup-action-btn-container pb-8 pt-6 bottom-0 bg-white">
        <div class="pt-8 flex justify-end">
          <vs-button size="large" @click="performAction()" action-btn>{{ (action == "Edit") ? "Save" : "Confirm" }}</vs-button>
          <vs-button color="danger" @click="linkPopupActive = false" size="large" action-btn class="ml-2">Cancel</vs-button>
        </div>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { PlusIcon } from "vue-feather-icons";
import moment from "moment";
import momentTz from "moment-timezone";
import S3ImageGenerator from "../../components/S3ImageGenerator";

export default {
  components: { PlusIcon, S3ImageGenerator },

  data() {
    return {
      appName: process.env.VUE_APP_NAME == "FlipPay" ? "Flip" : "Rello",
      introducers: [],
      linkRequests: [],
      users: [],
      action: "",
      title: "",
      description: "",
      introducerId: "",
      linkPopupActive: false,
      linkRequestId: null,
      merchantId: "",
      headerDescription: "",
      merchant: {},
      intervalMap: {}
    };
  },

  computed: {
    user() {
      return this.$store.state.AppActiveUser;
    },

    partnerId() {
      return (this.user.userType === "admin") ? this.user._id : this.user.partnerId;
    },

    checkNextUrl: function () {
      return function (data) {
        const connection = this.merchant.boxAndDice.connections.find((item) => item.introducerId == data._id);
        const hasNextUrl = (connection && connection.nextUrl && (Object.keys(connection.nextUrl).length > 0)) ? true : false;

        // If there is no nextUrl, start an interval to call getBoxAndDiceNextUrl
        if (!hasNextUrl && !this.intervalMap[data.introducerId]) {
          this.intervalMap[data.introducerId] = setInterval(() => {
            this.checkAdminLogin();
          }, 60000); // 60000 ms = 1 minute
        } else if (hasNextUrl) {
          clearInterval(this.intervalMap[data.introducerId]);
        }

        return hasNextUrl;
      };
    },
  },

  async mounted() {
    this.$validator.extend("urlFormat", {
      getMessage: () =>
        "The domain field is not a valid url.",
      validate: value =>
        value.match(
          /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,})/gi
        ) !== null
    });

    await this.checkAdminLogin();
    this.merchantId = JSON.parse(localStorage.user).userType == "admin" ? JSON.parse(localStorage.user).merchantId : JSON.parse(localStorage.user).partner.merchantId;
    this.getLinkRequestByMerchantId();
    this.getIntroducerByMerchantId();
    this.getUsers(this.partnerId);
    this.headerDescription = `Manage your integrations with ${this.appName}'s partners - you may still need to provide your ${this.appName} merchant account ID (<b>${this.merchantId}</b>) to the partner's support team before the integration will function, refer to the help centre for details.`;
    // Run the check when the component is mounted, in case of page refresh
    this.checkApiCallExpiry();
  },

  methods: {
    ...mapActions("linkRequest", ["createIntroducerLinkRequest", "fetchLinkRequestByMerchantId", "updateLinkRequestById"]),
    ...mapActions("partner", ["fetchApiUsersByPartnerId", "refreshBoxAndDiceSettings"]),
    ...mapActions("introducer", ["fetchIntroducersWithLinkRequest"]),
    ...mapActions("admin", ["checkLogin"]),

    async checkAdminLogin() {
      this.$vs.loading();
      await this.checkLogin().then(result => {
        this.$vs.loading.close();
        this.merchant = result.data.data;
      });
    },

    async getIntroducerByMerchantId() {
      await this.fetchIntroducersWithLinkRequest(this.partnerId).then((res) => {
        this.introducers = res.data.data;
      }).catch(ex => {
        this.$vs.notify({
          title: "Introducers",
          text: ex.data.message,
          color: "danger",
          icon: "error",
          position: "top-right",
        });
      });
    },

    async getLinkRequestByMerchantId() {
      this.$vs.loading();
      await this.fetchLinkRequestByMerchantId(this.partnerId).then((res) => {
        this.linkRequests = res.data.data;
        this.$vs.loading.close();
      }).catch(ex => {
        this.$vs.loading.close();
        this.$vs.notify({
          title: "Link Request",
          text: ex.response.data.message,
          color: "danger",
          icon: "error",
          position: "top-right",
        });

        if(ex.response.status === 400) {
          this.$router.push({ name: "settings" });
        }
      });
    },

    async editToken(id) {
      this.$router.push({
        name: "api-token-edit",
        params: { id: id },
      });
    },

    async getUsers(partnerId) {
      await this.fetchApiUsersByPartnerId(partnerId).then((result) => {
        this.users = result.data.data;
      }).catch((ex) => {
        this.$vs.notify({
          title: "Staff",
          text: ex.data.message,
          color: "danger",
          iconPack: "feather",
          icon: "icon-alert-circle",
          position: "top-right",
        });
      });
    },

    getStatus(data) {
      let status = "Not connected";

      if (data.linkRequest && data.linkRequest && data.linkRequest.status) {
        status = data.linkRequest.status;
      }

      return status;
    },

    changeStatusColor(data) {
      let bgClass = "badge primary";
      let status = null;

      if (data.linkRequest && data.linkRequest && data.linkRequest.status) {
        status = data.linkRequest.status;
      }

      if (status) {
        switch(status) {
          case "Rejected":
            bgClass = "badge danger";
            break;

          case "Connected":
            bgClass = "badge success";
            break;

          case "Pending":
            bgClass = "badge pending";
            break;
        }
      }

      return bgClass;
    },

    moment(date) {
      return moment(date).format("DD/MM/YYYY");
    },

    momentTime(date) {
      return moment(date).format("hh:mm:ss A");
    },

    momentTzTime(date, dateFormat = "DD/MM/YYYY") {
      return momentTz(date).tz("Australia/Sydney").format(dateFormat);
    },

    async updateStatus(introducerId, status) {
      this.linkPopupActive = false;

      this.$vs.loading();
      let obj = {
        id: this.linkRequestId,
        payload: {
          merchantId: this.partnerId,
          introducerId: introducerId,
        },
      };

      if (!["Deleted"].includes(status)) {
        obj.payload.status = status;
      }

      if (["Rejected", "Deleted"].includes(status)) {
        obj.payload.deleted = true;
      }

      await this.updateLinkRequestById(obj).then((res) => {
        this.$vs.notify({
          title: res.data.title,
          text: res.data.message,
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "success",
        });
        this.getIntroducerByMerchantId();
        this.getUsers(this.partnerId);
        this.$vs.loading.close();
      }).catch((ex) => {
        this.$vs.loading.close();
        this.$vs.notify({
          title: "Introducer",
          text: ex.data.message,
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger",
        });
      });
    },

    handleAction(action, data, linkRequestId = null) {
      this.introducerId = data._id;

      if (linkRequestId) {
        this.linkRequestId = linkRequestId;
      }

      switch(action) {
        case "add":
          this.action = "Pending";
          this.title = `Enable integration with ${data.name}?`;
          this.description = `You're about to <b>enable</b> the integration with <b class="uppercase">${data.name}</b>, allowing <b class="uppercase">${data.name}</b> to access your account to create requests, receive updates, etc - are you sure?`;
          break;

        case "remove":
          this.action = "Deleted";
          this.title = `Disable integration with ${data.name}?`;
          this.description = `You're about to <b>disable</b> the integration with <b class="uppercase">${data.name}</b>, preventing <b class="uppercase">${data.name}</b> from accessing your account to create requests, receive updates, etc - are you sure?`;
          break;

        case "accept":
          this.action = "Connected";
          this.title = "Accept link to this system?";
          this.description = "You're about to enable a link between your account and this external system, allowing the external system to access your account (to create request, receive updates, etc) - are you sure?";
          break;

        case "decline":
          this.action = "Rejected";
          this.title = "Decline link to this system?";
          this.description = "You're about to decline a request to link your account and this external system, blocking the external system from accessing your account (to create request, receive updates, etc) - are you sure?";
          break;
      }

      this.linkPopupActive = true;
    },

    performAction() {
      if (["Pending"].includes(this.action)) {
        this.addIntroducer(this.introducerId);
      } else {
        this.updateStatus(this.introducerId, this.action);
      }
    },

    addPopUpClass(enabled) {
      if (enabled) {
        document.body.classList.add("custom-popup");
      } else {
        document.body.classList.remove("custom-popup");
        this.introducerId = "";
        this.action = "";
        this.title = "";
        this.description = "";
        this.linkRequestId = null;
      }
    },

    async addIntroducer(introducerId) {
      let obj = {
        merchantId: this.partnerId,
        introducerId: introducerId,
      };

      this.linkPopupActive = false;
      this.$vs.loading();

      await this.createIntroducerLinkRequest(obj).then((res) => {
        this.$vs.loading.close();
        this.showToastMessage(res.data.title, res.data.message, "success");
        this.getIntroducerByMerchantId();
        this.getUsers(this.partnerId);
      }).catch((ex) => {
        this.$vs.loading.close();
        this.showToastMessage("Link request", ex.data.message, "error");
      });
    },

    async handleRefresh(introducerId) {
      // Check if the stored item should be cleared when the page is refreshed
      await this.checkApiCallExpiry();

      const checkRefreshTime = localStorage.getItem("boxAndDiceApiCall");

      if (checkRefreshTime) {
        this.showToastMessage("Refresh data", "Too many requests. Please try again after few minutes", "error");
        return;
      }

      let obj = {
        merchantId: this.partnerId,
        introducerId: introducerId,
      };

      this.$vs.loading();

      await this.refreshBoxAndDiceSettings(obj).then((res) => {
        this.$vs.loading.close();
        this.showToastMessage(res.data.title, res.data.message, "success");
        this.setApiCallTime();
        this.checkAdminLogin();
      }).catch((ex) => {
        this.$vs.loading.close();
        this.showToastMessage("Refresh data", ex.data.message, "error");
      });
    },

    setApiCallTime() {
      localStorage.setItem("boxAndDiceApiCall", new Date().toISOString());

      // Clear the localStorage item after 1 minute 5 seconds (65000 milliseconds)
      setTimeout(() => {
        localStorage.removeItem("boxAndDiceApiCall");
      }, 65000);
    },

    async checkApiCallExpiry() {
      const storedTime = localStorage.getItem("boxAndDiceApiCall");

      if (storedTime) {
        const storedDate = new Date(storedTime);
        const now = new Date();
        const timeDiff = now - storedDate;

        // If more than 65 seconds have passed, remove the item
        if (timeDiff > 65000) {
          localStorage.removeItem("boxAndDiceApiCall");
        }
      }
    },

    getSyncedTime(data) {
      let syncedTime = "";
      const connection = this.merchant.boxAndDice.connections.find((item) => item.introducerId == data._id);

      if (connection && connection.syncedAt) {
        syncedTime = this.momentTzTime(connection.syncedAt, "DD MMM YYYY, h:mm a");
      }

      return syncedTime;
    }
  },

  watch: {
    linkPopupActive(val) {
      this.addPopUpClass(val);
    }
  },

  beforeDestroy() {
    // Clear the interval when the component is destroyed
    Object.keys(this.intervalMap).forEach((id) => {
      clearInterval(this.intervalMap[id]);
    });
    this.intervalMap = {}; // Clear the map
  }
};
</script>
<style>
  .vs-popup--content {
    padding-bottom: 0px !important;
  }

  .popup-action-btn {
    border-top: 1px solid lightgray !important;
  }
</style>

